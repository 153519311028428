import React from 'react';

const ModalContentToS = () => {
  return (
    <div>
      <h2>Terms of Service</h2>
      <p style={{ color: 'black', lineHeight: '1.5em', paddingLeft: '16px', paddingRight: '16px' }}>Welcome to Know Him! By accessing or using our trivia app, website (knowhimgame.com), and services, you agree to be bound by these Terms of Service and our Privacy Policy. If you do not agree with any part of these Terms, please do not use our services.
Account Setup
You must create an account to use certain features of the Know Him app and services. You agree to provide accurate and complete information and to keep your account credentials secure. You are responsible for all activity under your account.
User Conduct
You agree to use our app, website, and services only for lawful purposes in accordance with these Terms. Specifically, you agree not to:

Violate any intellectual property rights
Harass, stalk, or abuse other users
Transmit viruses or malware
Attempt to gain unauthorized access to our systems
Use automated means to interact with our services

We reserve the right to terminate or restrict your access at any time if we reasonably believe you have violated these Terms.
User Content
Any trivia questions, comments, or other content you submit through our services remains yours. However, by submitting content, you grant us a worldwide, perpetual, royalty-free license to use, copy, distribute, and display that content in connection with operating and providing the Know Him services.
Third-Party Links
Our services may contain links to third-party websites that we do not control. We are not responsible for their content, privacy policies, or practices.
Disclaimers and Limitations
Our services are provided "as is" without warranties of any kind. We do not guarantee continuous or secure access. You understand that you use our services at your own risk.
To the fullest extent permitted by law, we disclaim all liability for any direct, indirect, punitive, incidental, special, or consequential damages arising out of or related to your use of Know Him services.
Modifications
We reserve the right to modify or terminate our services and these Terms at any time without notice.
Governing Law
These Terms and your use of Know Him shall be governed by and construed in accordance with the laws of [insert governing law/state].
By continuing to access or use our Know Him services, you accept and agree to abide by these Terms of Service. Contact us at legal@knowhimgame.com with any questions.</p>
    </div>
  );
};

export default ModalContentToS;