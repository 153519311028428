// PrivacyPolicy.js
import React from 'react';

function PrivacyPolicy() {
  return (
    <div>
      <div>
      <h2 style={{ textAlign: 'center' }}>Privacy Policy</h2>
    <p style={{ color: 'black', lineHeight: '1.5em', padding: '16px' }}>At Know Him, we value your privacy and are committed to protecting your personal information. This privacy policy explains how we collect, use, and safeguard the information you provide when using our trivia app and website.
Information We Collect
We may collect personal information such as your name, email address, and user profile data when you create an account or participate in our services. We also automatically collect non-personal information such as your device type, operating system, and usage data.
Use of Information
We use the information we collect to provide, improve, and personalize our Know Him trivia app and services. This includes processing game scores, sending updates and notifications, and analyzing usage trends. We will never sell or rent your personal information to third parties.
Data Sharing
We do not share your personal information with any third parties except when required by law or to protect our rights and property. Non-personal, aggregated data may be shared with partners for analytics purposes.
Data Security
We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet or storage is 100% secure.
Third-Party Links
Our app and website may contain links to third-party sites. We are not responsible for their privacy practices or content.
Children's Privacy
Our app and services are not intended for children under 13. We do not knowingly collect personal information from children.
Updates
We may update this privacy policy periodically. Any changes will be posted on this page.
Contact Us
If you have any questions about this privacy policy or our data practices, please contact us at privacy@knowhim.com.
By using our Know Him trivia app and website, you consent to the data practices outlined in this privacy policy.</p>
    </div>
    </div>
  );
}

export default PrivacyPolicy;