import React, { useState } from 'react';
import { Parallax } from 'react-parallax';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';
import ModalContentToS from './ModalContentToS';
import ModalContentPrivacy from './ModalContentPrivacy';
import ReactGA from 'react-ga';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy';

// Import stock images
import backgroundImage from './assets/background-image.png';
import featureImage1 from './assets/screen_cap_1.png';
import featureImage2 from './assets/screen_cap_2.png';
// import icon from './assets/jesus_icon.png';
import jesusImage from './assets/jesus.png';
import largeLinearLogo from './assets/large_linear_logo_red.png';
import jesusIconKnockout from './assets/jesus_icon_knockout.png';
import appleAppStoreButton from './assets/apple-app-store-button.png';
import googlePlayStoreButton from './assets/android-app-store-button.png';
import kh_deck from './assets/kh_deck.png'; // adjust the path as needed
import kh_deck2 from './assets/deck-2.png'; // replace with your second image
import kh_deck3 from './assets/deck-3.png'; // replace with your third image
import jesusIconBg from './assets/jesus_icon_bg.png';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import kh_logo_deck from './assets/kh_logo_deck.png';
const isMobile = window.innerWidth <= 600;

const App = () => {
  ReactGA.initialize('G-RE1NMZE1TB');
  ReactGA.pageview(window.location.pathname + window.location.search);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ __html: '' });

  const handleLinkClick = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const Modal = () => {
    return (
      <div className="modal" style={{ display: showModal ? 'block' : 'none' }}>
        <div className="modal-content">
          <span className="close" onClick={() => setShowModal(false)}>
            &times;
          </span>
          {modalContent === 'tos' && <ModalContentToS />}
          {modalContent === 'privacy' && <ModalContentPrivacy />}
        </div>
      </div>
    );
  };

  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={
        <div>
        <div>
          <header>
            <nav>
              <a href="#download">Download App</a>
              <a href="#deck">Companion Deck</a>
              <a href="#about">About</a>
            </nav>
          </header>
          <main>
            <Parallax bgImage={backgroundImage} strength={200}>
              <section id="home" className="home-section">
                <div className='logo-wrapper'>
                  <img src={largeLinearLogo} alt="Large Linear Logo" className="large-linear-logo" />            
                </div>
                <div className="content">
                  <img src={jesusImage} alt="Jesus" className="jesus-image" />            
                </div>
              </section>
            </Parallax>
            <section id="download" style={{ backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)' }}>
              <div className="feature-images">
                <img src={featureImage1} alt="Feature 1" />
                <img src={featureImage2} alt="Feature 2" />
              </div>
              <div className="feature-text">
                <h2>Explore trivia questions from the Gospels of Matthew, Mark, Luke, and John.</h2>
                <h2>All ages can play together using adjustable difficulty settings.</h2>
              </div>

            <div className="download-buttons-wrapper">
              <div style={{ position: 'relative', width: 'fit-content', display: 'inline-block' }}>
                <a href="https://apps.apple.com/us/app/know-him/id6560104973" target="_blank" rel="noopener noreferrer">
                  <img src={appleAppStoreButton} alt="Download on the Apple App Store" style={{ width: 'auto' }} />
                </a>
              </div>
              <div style={{ position: 'relative', width: 'fit-content', display: 'inline-block' }}>
                <img src={googlePlayStoreButton} alt="Get it on Google Play" style={{ width: 'auto', opacity: 0.3 }} />
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#000', fontSize: '14px', fontWeight: 'bold' }}>
                  Coming Soon
                </div>
              </div>
            </div>


            </section>
            

    <section id="deck" style={{ 
        background: 'linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.25) 200%)', 
        backgroundBlendMode: 'multiply' 
      }}>    
        <div className='companion_wrapper'>
          <Carousel showThumbs={false} showStatus={false}>
            <div>
              <img src={kh_deck} alt="Know Him Companion Deck" className="carousel-image1" style={{ marginBottom: '16px' }} />
            </div>
            <div>
              <img src={kh_deck2} alt="Know Him Companion Deck" className="carousel-image2" style={{ marginBottom: '32px', marginTop: '8px' }} />
            </div>
            <div>
              <img src={kh_deck3} alt="Know Him Companion Deck" className="carousel-image3" style={{ marginTop: '25px' }} />
            </div>
          </Carousel>
          <div className='deck-logo-wrapper'>
            <img src={kh_logo_deck} alt="Know Him Companion Deck" className="logo-deck" />    
            <div className='now-available-wrapper'>Now Available!</div>
          </div>
          <div className='deck-wrapper'>
            <div className="feature-text-deck">
              <h2>40 Gospel cards with Jesus facts.</h2>
              <h2>Pairs with the Know Him trivia app.</h2>
              <h2>First player to collect all four Gospels wins!</h2>
            </div>
            {/* <a href="#" className="btn">Buy Now</a> */}
          </div>
        </div>
        </section>

          <section id="about" style={{ 
            width: "100%", 
            margin: "0 auto", 
            backgroundImage: 'linear-gradient(to top, #dfe9f3 0%, white 100%)', 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center',
            boxSizing: 'border-box'
          }}>
          <h2>About Know Him</h2>
          <div className='kh-about-wrapper'>
            <p>
              Know Him is a labor of love, created by a team of passionate believers who aim to share the transformative message of Jesus Christ through an engaging and entertaining experience. Our goal is to inspire people to dive deeper into the Gospels and develop a closer relationship with our Lord and Savior.
            </p>
            <p>
              Join us on this exciting journey and discover the life-changing truth of Jesus Christ through the power of trivia and biblical knowledge.
            </p>
          </div>
        </section>

          <div className='jesus-creation' style={{
            backgroundImage: `url(${jesusIconBg})`,
            backgroundSize: '48%',
            animation: 'animateBackground 300s linear infinite',
            position: 'relative',
          }}>
            <div className='jesus-ascend-top-bumper'></div>
            <div className='jesus-ascend-wrapper'>
              <div className='jesus-ascend-bumper'></div>
              <img 
              src={jesusIconKnockout} 
              alt="Know Him" 
              style={isMobile ? { width: 'auto', height: '90%', overflow: 'hidden',} : { width: 'auto', height: 250 }} 
            />
            <div className='jesus-ascend-bumper'></div>
            </div>
            <div className='jesus-ascend-bottom-bumper'></div>
          </div>
          
          </main>
          <footer>
            <p>&copy; 2024 Know Him, LLC</p>
            <button onClick={() => window.location.href = "mailto:contact@knowhimgame.com"}>Contact</button>
            <a href="/privacy-policy">Privacy Policy</a>
            <button onClick={() => handleLinkClick('tos')}>Terms of Service</button>
            <Modal />
          </footer>
        </div>
        </div>
      } />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  </BrowserRouter>
  );
};

export default App;